import * as React from 'react'
import Layout from '../components/layout'
import { graphql, Link } from "gatsby"
import '../styles/main.scss';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  return (
    <Layout pageTitle="Topics">
      <div class="top-bar">
        <h1 class="left-side">Our Topics</h1>
      </div>
      <div className="page">
        <div className="page-content">
          <p>There's so much in this world to learn about! These are the categories of topics currently featured on LearningDB. They are organized under two major sections - <b>General Topics</b> and <b>Special Topics</b>.</p>
          <p><b>General Topics</b> include resources for topics typically covered in traditional learning environments, like high school or college. These can help if you're looking for further explanation on certain concepts, resources for a research assignment, or studying for major exams such as midterms, finals, or AP Exams.</p>
          <p><b>Special Topics</b> include resources written for specific topics. <b>General Topics</b> may cover topics more broadly, such as Computer Science as a whole, whereas <b>Special Topics</b> would have articles specifically on Minecraft Java Plugin Development.</p>
          <h1>General Topics</h1>
          {
            data.general.edges.map(edge => (
              <h2 className="category-header"><Link to={edge.node.frontmatter.shortname}>{edge.node.frontmatter.title}</Link></h2>
            ))
          }
          <h1>Special Topics</h1>
          {
            data.special.edges.map(edge => (
              <h2 className="category-header"><Link to={edge.node.frontmatter.shortname}>{edge.node.frontmatter.title}</Link></h2>
            ))
          }
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query TopicsPageQuery {
    general: allMarkdownRemark(
      sort: {order: ASC, fields: [frontmatter___shortname]}
      limit: 1000
      filter: {frontmatter: {type: {eq: "category"}}, fileAbsolutePath: {regex: "/topics\\/general/"}}
    ) {
      edges {
        node {
          frontmatter {
            type
            category
            shortname
            title
          }
        }
      }
    }

    special: allMarkdownRemark(
      sort: {order: ASC, fields: [frontmatter___shortname]}
      limit: 1000
      filter: {frontmatter: {type: {eq: "category"}}, fileAbsolutePath: {regex: "/topics\\/special/"}}
    ) {
      edges {
        node {
          frontmatter {
            type
            category
            shortname
            title
          }
        }
      }
    }
  }
`
